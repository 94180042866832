body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.small-loader {
  height: 25px;
}

#navbarMain {
  background: #095d98;
  height: 5rem;
  margin-bottom: 100px;
  width: 100%;
}
#navbarMain .logoMain {
  background-color: white;
  padding: 20px;
  height: 125px;
  margin: 5px 20px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 20px;
  /* laeft: 20px; */
}

.col-md-12 {
  margin: 0.3rem;
}

.file-download-button {
  background: none;
  border: none;
  padding: 0;
  color: #007bff;
  cursor: pointer;
}

.file-download-button:hover {
  text-decoration: underline;
}
